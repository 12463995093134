import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  CompanyEmployeeCountNumberEnum,
  CompanySizeNumberEnum,
  EsgLevelNumberEnum,
  FormTypeEnum,
  TopicEnum,
  TopicValues
} from '../enums/diagnosis.enum';
import {
  IActiveQuestion,
  IAnswer,
  IBlockQuestion,
  ICompany,
  IDiagnosisData,
  IDiagnosisForm,
  IDiagnosisProgress,
  IDiagnosisResult,
  IQuestion,
  IQuestionAnswer
} from '../interfaces/diagnosis.interface';
import { IGenericResponse } from '../interfaces/generic.interface';
import { CustomerService } from './customer.service';
import { EsgTypeEnum } from '../enums/esg.enum';
import {
  mainDiagnosisCompany,
  employeeCountData
} from '../utils/main-diagnosis-data';

@Injectable({
  providedIn: 'root'
})
export class DiagnosisService {
  apiUrl = environment.apiUrl;
  handleErrorCustom = { handleErrorCustom: 'true' };

  diagnosisData = signal<IDiagnosisData | null>(null);

  constructor(
    private http: HttpClient,
    private customerService: CustomerService
  ) {}

  getDiagnosisForm(formType: FormTypeEnum): Observable<IDiagnosisForm> {
    return this.http.get<IDiagnosisForm>(`${this.apiUrl}/form/${formType}`);
  }

  getDiagnosisData(): Observable<IDiagnosisData> {
    let customerId = this.customerService.customerActive()?.id ?? null;
    return this.http
      .get<IDiagnosisData>(`${this.apiUrl}/diagnostic/company/${customerId}`)
      .pipe(
        map((data) => {
          const companyForm = structuredClone(mainDiagnosisCompany);
          const employeeCountForm = structuredClone(employeeCountData);

          let companyQuestions = [...companyForm.blocks, employeeCountForm];

          this.setCompanyShort(data.companyShort, companyQuestions);

          data.form.segments.unshift(companyForm);

          let segment = data.form.segments.find(
            (segment) => TopicValues[segment.topic] === TopicEnum.SOCIAL
          );

          if (segment) {
            segment.blocks.unshift(employeeCountForm);
          }

          data.form = {
            ...data.form,
            progress: this.calculateProgress(data.form)
          };

          return data;
        })
      );
  }

  setCompanyShort(company: ICompany, blockQuestions: IBlockQuestion[]): void {
    company.esgLevel =
      company.esgLevel != null
        ? EsgLevelNumberEnum[company.esgLevel as any].toString()
        : null!;
    company.size =
      company.size != null
        ? CompanySizeNumberEnum[company.size as any].toString()
        : null!;
    company.employeeCount =
      company.employeeCount != null
        ? CompanyEmployeeCountNumberEnum[
            company.employeeCount as any
          ].toString()
        : null!;

    blockQuestions.forEach((block) => {
      block.questions.forEach((question) => {
        this.handleQuestions(company, question);
      });
    });
  }

  private handleQuestions(company: ICompany, question: IQuestion) {
    let value = company[question.id as keyof ICompany];

    if (value) {
      value
        .toString()
        .split('|')
        .forEach((answer) => {
          if (!question.answers.some((a) => a.id === answer)) {
            question.answers.push({ id: answer, title: answer });
          }

          question.userAnswers.push(answer);
        });
    }
  }

  calculateProgress(form: IDiagnosisForm): IDiagnosisProgress {
    let progress: { [key: number]: number } = {
      [EsgTypeEnum.COMPANY]: 0,
      [EsgTypeEnum.ENVIRONMENTAL]: 0,
      [EsgTypeEnum.SOCIAL]: 0,
      [EsgTypeEnum.GOVERNANCE]: 0
    };

    let activeQuestion: IActiveQuestion | null = null;

    form.segments.forEach((segment, i) => {
      let segmentProgress = 0;
      let segmentLength = 0;

      segment.blocks.forEach((block, j) => {
        segmentLength += block.questions.length;
        block.questions.forEach((question) => {
          if (question.userAnswers.length > 0) {
            segmentProgress++;
          } else if (!activeQuestion) {
            activeQuestion = {
              topic: i,
              block: j
            };
          }
        });
      });

      progress[i] = Math.round((segmentProgress / segmentLength) * 100);
    });

    if (!activeQuestion) {
      activeQuestion = {
        topic: form.segments.length - 1,
        block: form.segments[form.segments.length - 1].blocks.length - 1
      };
    }

    return {
      company: progress[EsgTypeEnum.COMPANY],
      environmental: progress[EsgTypeEnum.ENVIRONMENTAL],
      social: progress[EsgTypeEnum.SOCIAL],
      governance: progress[EsgTypeEnum.GOVERNANCE],
      activeQuestion
    };
  }

  saveDraftDiagnosisForm(data: IDiagnosisData): Observable<IDiagnosisData> {
    let customerId = this.customerService.customerActive()?.id ?? null;
    data.companyId = customerId;
    return this.http.post<IDiagnosisData>(
      `${this.apiUrl}/diagnostic/draft`,
      data,
      {
        headers: this.handleErrorCustom
      }
    );
  }

  saveDiagnosisForm(
    data: IDiagnosisData,
    isMainDiagnosis: boolean = false
  ): Observable<IDiagnosisData> {
    if (isMainDiagnosis) {
      let customerId = this.customerService.customerActive()?.id ?? null;
      data.companyId = customerId;
    }
    return this.http.post<IDiagnosisData>(`${this.apiUrl}/diagnostic`, data, {
      headers: this.handleErrorCustom
    });
  }

  resetDiagnosisForm(diagnosisId: string | null): Observable<IDiagnosisData> {
    return this.http.delete<IDiagnosisData>(
      `${this.apiUrl}/diagnostic/${diagnosisId}`
    );
  }

  getDiagnosisResult(
    diagnosisId: string
  ): Observable<IGenericResponse<IDiagnosisResult>> {
    return this.http.get<IGenericResponse<IDiagnosisResult>>(
      `${this.apiUrl}/diagnostic/${diagnosisId}/calc`
    );
  }

  createQuestionAnswer(
    data: IQuestionAnswer
  ): Observable<IGenericResponse<IAnswer>> {
    let customerId = this.customerService.customerActive()?.id ?? null;
    data.companyId = customerId;
    return this.http.post<IGenericResponse<IAnswer>>(
      `${this.apiUrl}/questionAnswer`,
      data
    );
  }
}
