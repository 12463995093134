import { CompanyEmployeeCountEnum } from './../enums/diagnosis.enum';
import {
  CompanyLegalModelEnum,
  CompanySizeEnum,
  EsgLevelEnum
} from '../enums/diagnosis.enum';
import { ISegment } from '../interfaces/diagnosis.interface';
import { QuestionType } from './../enums/question-type.enum';

export const mainDiagnosisCompany: ISegment = {
  topic: 0,
  blocks: [
    {
      id: '001',
      title: null,
      order: 1,
      questions: [
        {
          id: 'corporateName',
          title: 'Informe a razão social da organização.',
          subtitle: null,
          label: null,
          quote: null,
          order: 1,
          type: QuestionType.TEXTAREA,
          answers: [],
          userAnswers: []
        },
        {
          id: 'state',
          title: 'Informe a localização da sede da organização.',
          subtitle: null,
          label: 'Estado',
          quote: null,
          order: 1,
          type: QuestionType.SELECT,
          answers: [],
          userAnswers: []
        },
        {
          id: 'city',
          title: null,
          subtitle: null,
          label: 'Cidade',
          quote: null,
          order: 1,
          type: QuestionType.SELECT,
          answers: [],
          userAnswers: []
        }
      ]
    },

    {
      id: '002',
      title:
        'Selecione a seguir <strong>a atividade</strong> principal da organização:',
      order: 2,
      questions: [
        {
          id: 'segment',
          title: null,
          subtitle: null,
          label: null,
          quote: null,
          order: 1,
          type: QuestionType.OPTION,
          allowCustomAnswers: true,
          answers: [
            {
              id: 'Agronegócio',
              title: 'Agronegócio'
            },
            {
              id: 'Alimentos e Bebidas',
              title: 'Alimentos e Bebidas'
            },
            {
              id: 'Setor financeiro',
              title: 'Setor financeiro'
            },
            {
              id: 'Bens de Capital',
              title: 'Bens de Capital'
            },
            {
              id: 'Educação',
              title: 'Educação'
            },
            {
              id: 'Setor Elétrico',
              title: 'Setor Elétrico'
            },
            {
              id: 'Imobiliários e Shopping',
              title: 'Imobiliários e Shopping'
            },
            {
              id: 'Mineração e Siderurgia',
              title: 'Mineração e Siderurgia'
            },
            {
              id: 'Papel e Celulose',
              title: 'Papel e Celulose'
            },
            {
              id: 'Petróleo e Gás',
              title: 'Petróleo e Gás'
            },
            {
              id: 'Saúde',
              title: 'Saúde'
            },
            {
              id: 'Transportes',
              title: 'Transportes'
            },
            {
              id: 'Varejo',
              title: 'Varejo'
            },
            {
              id: 'Tecnologia e Telecomunicações',
              title: 'Tecnologia e Telecomunicações'
            }
          ],
          userAnswers: []
        }
      ]
    },
    {
      id: '003',
      title: null,
      order: 3,
      questions: [
        {
          id: 'legalModel',
          title: 'Qual é o modelo jurídico da organização?',
          subtitle: null,
          label: null,
          quote: null,
          order: 1,
          type: QuestionType.OPTION,
          allowCustomAnswers: true,
          answers: [
            {
              id: CompanyLegalModelEnum.PRIVATE_COMPANY,
              title: 'Empresa privada'
            },
            {
              id: CompanyLegalModelEnum.OUTSOURCED,
              title: 'Terceiro setor'
            },
            {
              id: CompanyLegalModelEnum.COOPERATIVE,
              title: 'Cooperativa'
            },
            {
              id: CompanyLegalModelEnum.PUBLIC_INSTITUTION,
              title: 'Instituição pública'
            }
          ],
          userAnswers: []
        }
      ]
    },
    {
      id: '004',
      title: null,
      order: 4,
      questions: [
        {
          id: 'size',
          title: 'Informe o porte da empresa de acordo com o seu faturamento:',
          subtitle: null,
          label: null,
          quote: null,
          order: 1,
          type: QuestionType.OPTION,
          answers: [
            {
              id: CompanySizeEnum.MICRO,
              title: 'Microempresa - Faturamento anual até R$ 360 mil'
            },
            {
              id: CompanySizeEnum.SMALL,
              title:
                'Empresa de Pequeno Porte - Faturamento anual entre R$ 360 mil e R$ 4,8 milhões'
            },
            {
              id: CompanySizeEnum.MEDIUM,
              title:
                'Empresa de Médio Porte - Faturamento anual entre R$ 4,8 milhões e R$ 20 milhões'
            },
            {
              id: CompanySizeEnum.LARGE,
              title:
                'Empresa de Grande Porte - Faturamento anual acima de R$ 20 milhões'
            }
          ],
          userAnswers: []
        }
      ]
    },
    {
      id: '005',
      title: null,
      order: 5,
      questions: [
        {
          id: 'certification',
          title:
            'Assinale a seguir <strong>as certificações</strong> que a empresa possui:',
          subtitle: null,
          label: null,
          quote: null,
          order: 1,
          type: QuestionType.CHECKBOX,
          allowCustomAnswers: true,
          answers: [
            {
              id: 'ISO 14001',
              title: 'ISO 14001'
            },
            {
              id: 'ISO 26000',
              title: 'ISO 26000'
            },
            {
              id: 'Energia Verde',
              title: 'Energia Verde'
            },
            {
              id: 'B Corp Certification',
              title: 'B Corp Certification'
            },
            {
              id: 'Carbon Disclosure Project (CDP)',
              title: 'Carbon Disclosure Project (CDP)'
            },
            {
              id: 'Fair Trade Certification',
              title: 'Fair Trade Certification'
            },
            {
              id: 'Orgânicos e alimentos sustentáveis',
              title: 'Orgânicos e alimentos sustentáveis'
            },
            {
              id: 'Ranking Great Place to Work',
              title: 'Ranking Great Place to Work'
            }
          ],
          userAnswers: []
        }
      ]
    },
    {
      id: '006',
      title: null,
      order: 6,
      questions: [
        {
          id: 'esgLevel',
          title:
            'Qual <strong>das alternativas</strong> a seguir melhor representa o que diz respeito à Política ESG da organização?',
          subtitle: null,
          label: null,
          quote: null,
          order: 1,
          type: QuestionType.OPTION,
          answers: [
            {
              id: EsgLevelEnum.NOT_IMPLEMENTED,
              title: 'Não temos implementada'
            },
            {
              id: EsgLevelEnum.IMPLEMENTING,
              title: 'Estamos em fase inicial de implementação'
            },
            {
              id: EsgLevelEnum.NON_RESULTS,
              title:
                'Já implementamos, mas ainda não temos resultados consistentes para avaliação'
            },
            {
              id: EsgLevelEnum.CONSISTENT_RESULTS,
              title:
                'Já implementamos, e temos resultados consistentes para a avaliação'
            },
            {
              id: EsgLevelEnum.REFERENCE_COMPANY,
              title: 'Somos referência em nosso mercado de atuação'
            }
          ],
          userAnswers: []
        }
      ]
    }
  ]
};

export const employeeCountData = {
  id: '006',
  title: null,
  order: 1,
  questions: [
    {
      id: 'employeeCount',
      title: 'Informe quantos colaboradores trabalham na empresa atualmente',
      subtitle: null,
      label: null,
      quote: null,
      order: 1,
      type: QuestionType.OPTION,
      answers: [
        {
          id: CompanyEmployeeCountEnum.ZERO_TO_TEN,
          title: '0 a 10 colaboradores'
        },
        {
          id: CompanyEmployeeCountEnum.ELEVEN_TO_FIFTY,
          title: '11 a 50 colaboradores'
        },
        {
          id: CompanyEmployeeCountEnum.FIFTY_ONE_TO_TWO_HUNDRED,
          title: '51 a 200 colaboradores'
        },
        {
          id: CompanyEmployeeCountEnum.TWO_HUNDRED_AND_ONE_TO_FIVE_HUNDRED,
          title: '201 a 500 colaboradores'
        },
        {
          id: CompanyEmployeeCountEnum.FIVE_HUNDRED_AND_ONE_TO_ONE_THOUSAND,
          title: '501 a 1000 colaboradores'
        },
        {
          id: CompanyEmployeeCountEnum.MORE_THAN_ONE_THOUSAND,
          title: 'Mais de 1000 colaboradores'
        }
      ],
      userAnswers: []
    }
  ]
};
